* {
  box-sizing: border-box;
}

body {
  height: 99vh;
}
#root {
  height: 100vh;
  /* border-bottom: 10vh solid rgb(197, 197, 197); */
  /* box-sizing: border-box; */
  background: white;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Bu, elemanların dikey olarak sıralanmasını sağlar */
  height: 100vh;
  overflow: hidden;
  /* border: solid 2px red; */
  /* background: yellow; */
}

.container canvas {
  /* canvas için özel stiller buraya gelebilir, eğer gerekliyse */
  height: 100%;
  /* width: 100vw; */
  /* border: solid 2px blue; */
  /* background: green; */
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 10px;
  padding: 0;
  margin-top: 6px;
  position: absolute;
  top: 6px;
  border: outset 3px #c6c6c6;
  background: #e0e0e0;
  left: 10px;
}

.footer {
  display: none;
}

.styled-select {
  /* padding: 7px;
    font-size: 14px;
    border: none;
    background: #dfdfdf;
    outline: none;
    appearance: none;
    font-weight: 700; */
}

.footer {
  /* display: none; Hide camera drop down for now */
}

#DebugMsg {
  position: absolute;
  top: 0;
  padding: 5px;
  /* width: 90%; */
  text-align: center;
  color: yellow;
  text-shadow: 4px 3px black, 1px 1px 1px black, -1px -1px 2px black;
  font-weight: bold;
  font-size: 2em;
  border-bottom: 0.1em red solid;
  padding-right: 2em;
}

canvas {
  /* border: solid 5px red;
    height: 88vh;
    width: 96vw; */
}
nav {
  display: none;
}

h1 {
  text-align: center;
  position: absolute;
  top: 50%;
  width: 100%;
}
h1 sup {
  font-size: 0.6em;
  margin-left: -8px;
}
