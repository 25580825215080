* {
  padding: 0;
  margin: 0;
}
.App {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}
